<template>
  <div class="customize_wrap">
    <div class="title">
      <div class="logo" @click="this.$router.push('/')"></div>
      <h1>{{ this.$t("avatar.avatar_configure") }}</h1>
      <div class="reset" @click="this.click_reset_avatar">
        {{ this.$t("avatar.reset") }}
      </div>
    </div>

    <div class="group">
      <PerfectScrollbar>
        <div class="container">
          <div
            class="box"
            v-for="(value, index) in this.group_data"
            :key="index"
            :style="{ backgroundImage: 'url(' + value.thumbnail_url + ')' }"
            :class="{ selected: value.selected }"
            @click="this.click_avatar_item_group(value.group_id)"
          ></div>
        </div>
      </PerfectScrollbar>
    </div>
    <div class="main">
      <div class="item">
        <PerfectScrollbar>
          <div class="container">
            <div
              class="box"
              v-for="(value, index) in this.item_data"
              :key="index"
              :style="{ backgroundImage: 'url(' + value.thumbnail_url + ')' }"
              :class="{
                selected: this.is_selected_item(value.item_id),
              }"
              @click="this.click_avatar_item(value.group_id, value.item_id)"
            >
              <div
                class="bought"
                v-if="
                  typeof value.bought_id != 'undefined' && value.bought_id > 0
                "
              ></div>
            </div>
          </div>
        </PerfectScrollbar>
      </div>
      <div class="character" :class="{ magnifying: this.is_magifying }">
        <div
          class="layer"
          v-for="(value, index) in this.layers"
          :key="index"
          :style="{ backgroundImage: 'url(' + value.image_url + ')' }"
        ></div>
      </div>
    </div>
  </div>

  <div class="button_wrap">
    <button class="button middle outline" @click="this.$router.push('/mypage')">
      {{ this.$t("common.cancel") }}
    </button>
    <button class="button red middle" @click="this.save_avatar">
      {{ this.$t("common.save") }}
    </button>
  </div>

  <Transition name="toast">
    <div class="toast" v-if="this.show_toast">{{ this.toast_text }}</div>
  </Transition>

  <Spinner v-show="processing" />
</template>

<script>
import { PerfectScrollbar } from "vue3-perfect-scrollbar";
import "vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css";
export default {
  name: "AvatarCustomize",
  components: {
    PerfectScrollbar,
  },
  metaInfo() {
    return {
      title: this.$t("meta.title") + " :: " + this.$t("meta.avatar"),
      og: {
        title: this.$t("meta.title") + " :: " + this.$t("meta.avatar"),
      },
    };
  },
  data: function () {
    return {
      process_queue: [],
      group_data: [],
      item_data: [],
      layers: [],

      original_data: [], // 원래 선택된 데이터 (초기화 시 필요)
      selected_data: [], // {group_id: 0, item_id: 0, data: [],}, ...

      is_magifying: false, // 아바타 확대 여부
      magnifying_group_names: ["EYE", "EYEBROW", "HAIR", "LIP"], // 확대할 그룹 이름

      show_toast: false,
      toast_text: "",
    };
  },
  computed: {
    processing() {
      if (this.process_queue.length <= 0) return false;
      else return true;
    },
  },
  watch: {
    selected_data: {
      deep: true,
      handler: function () {
        this.init_layer();

        for (let i = 0; i < this.selected_data.length; i++) {
          let item_data = this.selected_data[i].data;
          for (let j = 0; j < item_data.length; j++) {
            this.layers.push({
              layer: item_data[j].layer,
              image_url:
                process.env.VUE_APP_API_URL +
                "/resource/get/avatar/data/" +
                item_data[j].data_id +
                "/" +
                item_data[j].item_image,
            });
          }
        }

        this.layers.sort((a, b) => a.layer - b.layer);
      },
    },
  },
  mounted() {
    this.init_layer();
    this.load_avatar_item_group();
    this.load_user_avatar_item_data();
  },
  methods: {
    // 레이어 초기화
    init_layer: function () {
      this.layers.splice(0, this.layers.length);
    },

    load_avatar_item_group: function () {
      if (!this.storage.is_logged_in()) return;

      let self = this;
      self.process_queue.push(1);

      this.axios
        .post(
          process.env.VUE_APP_API_URL + "/avatar/group/list",
          {
            user_id: this.storage.get_user_id(),
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then(function (response) {
          let resp_data = response.data;

          // 데이터 비우기
          self.group_data.splice(0);

          // 데이터가 있다면...
          if (typeof resp_data != "undefined" && resp_data != null) {
            for (let i = 0; i < resp_data.length; i++) {
              resp_data[i].selected = false;
              resp_data[i].thumbnail_url =
                process.env.VUE_APP_API_URL +
                "/resource/get/avatar/group/" +
                resp_data[i].group_id +
                "/" +
                resp_data[i].group_thumbnail;

              self.group_data.push(resp_data[i]);
            }
          }
        })
        .catch(function (error) {
          alert(error);
        })
        .finally(function () {
          self.process_queue.pop();
        }); //-- axios
    },

    click_avatar_item_group: function (group_id) {
      for (let i = 0; i < this.group_data.length; i++) {
        this.group_data[i].selected = false;
        if (this.group_data[i].group_id == group_id) {
          this.group_data[i].selected = true;

          // 이름으로 확대 여부 판단하기
          let group_name = this.group_data[i].group_name;
          if (this.magnifying_group_names.includes(group_name)) {
            this.is_magifying = true;
          } else {
            this.is_magifying = false;
          }
        }
      }

      this.load_avatar_item(group_id);
    },

    load_avatar_item: function (group_id) {
      if (!this.storage.is_logged_in()) return;

      let self = this;
      self.process_queue.push(1);

      this.axios
        .post(
          process.env.VUE_APP_API_URL + "/avatar/item/list",
          {
            user_id: this.storage.get_user_id(),
            group_id: group_id,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then(function (response) {
          let resp_data = response.data;

          // 데이터 비우기
          self.item_data.splice(0);

          // 데이터가 있다면...
          if (typeof resp_data != "undefined" && resp_data != null) {
            for (let i = 0; i < resp_data.length; i++) {
              resp_data[i].thumbnail_url =
                process.env.VUE_APP_API_URL +
                "/resource/get/avatar/item/" +
                resp_data[i].item_id +
                "/" +
                resp_data[i].item_thumbnail;

              self.item_data.push(resp_data[i]);
            }
          }
        })
        .catch(function (error) {
          alert(error);
        })
        .finally(function () {
          self.process_queue.pop();
        }); //-- axios
    }, //-- load_avatar_item

    click_avatar_item: function (group_id, item_id) {
      // 그룹 타입 검색하기
      let current_group_type = "BASE";
      for (let i = 0; i < this.group_data.length; i++) {
        // 그룹 아이디가 있으면 삭제
        if (this.group_data[i].group_id == group_id) {
          current_group_type = this.group_data[i].group_type;
          break;
        }
      }

      // 기본 그룹 (몸통 등)이 아니라면, 현재 선택된 아이템을 다시 누르면 선택 해제하기
      if (current_group_type != "BASE") {
        // 현재 선택된 아이템이라면...
        if (this.is_selected_item(item_id)) {
          for (let i = 0; i < this.selected_data.length; i++) {
            // 그룹 아이디가 있으면 삭제
            if (this.selected_data[i].group_id == group_id) {
              this.selected_data.splice(i, 1);
              i--;
            }
          }
          // 종료...
          return;
        }
      }

      for (let i = 0; i < this.selected_data.length; i++) {
        // 그룹 아이디가 있으면 삭제
        if (this.selected_data[i].group_id == group_id) {
          this.selected_data.splice(i, 1);
          i--;
        }
      }

      // 데이터 가져오기
      let item_data = [];
      for (let i = 0; i < this.item_data.length; i++) {
        if (this.item_data[i].item_id == item_id) {
          // item_data = this.item_data[i].item_data;
          item_data = JSON.parse(JSON.stringify(this.item_data[i].item_data));
          break;
        }
      }

      let new_item = {
        group_id: group_id,
        item_id: item_id,
        data: item_data,
      };

      this.selected_data.push(new_item);
    },

    is_selected_item: function (item_id) {
      for (let i = 0; i < this.selected_data.length; i++) {
        if (this.selected_data[i].item_id == item_id) {
          return true;
        }
      }

      return false;
    }, //-- is_selected_item

    // 사용자 아바타 정보 먼저 가져오기 (있는지 확인 등)
    load_user_avatar_item_data: function () {
      if (!this.storage.is_logged_in()) return;

      let self = this;
      self.process_queue.push(1);

      this.axios
        .post(
          process.env.VUE_APP_API_URL + "/auth/get",
          {
            user_id: this.storage.get_user_id(),
            required: "YES",
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then(function (response) {
          if (response.data.result != "OK") {
            self.$swal.fire({
              text: this.$t("common.error_occur"),
              icon: "error",
            });

            return;
          }

          let data = response.data.data;
          if (
            typeof data.avatar_id != "undefined" &&
            parseInt(data.avatar_id) > 0
          ) {
            self.load_avatar_item_map(parseInt(data.avatar_id));
          } else {
            self.load_avatar_item_data(); // 정보가 없으면 랜덤 생성으로 넘어감
          }
        })
        .catch(function (error) {
          alert(error);
        })
        .finally(function () {
          self.process_queue.pop();
        }); //-- axios
    }, //-- load_user_avatar_item_data

    // 아바타 매핑 데이터 가져오기
    load_avatar_item_map: function (avatar_id) {
      if (!this.storage.is_logged_in()) return;

      let self = this;
      self.process_queue.push(1);

      this.axios
        .post(
          process.env.VUE_APP_API_URL + "/avatar/map/list",
          {
            user_id: this.storage.get_user_id(),
            avatar_id: avatar_id,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then(function (response) {
          let resp_data = response.data;

          // 데이터
          let map_data = [];

          // 데이터가 있다면...
          if (typeof resp_data != "undefined" && resp_data != null) {
            for (let i = 0; i < resp_data.length; i++) {
              resp_data[i].data = resp_data[i].item_data;

              let item = {
                group_id: resp_data[i].group_id,
                item_id: resp_data[i].item_id,
                data: resp_data[i].item_data,
              };

              map_data.push(item);
            }

            self.selected_data = map_data;

            // 오리지널 데이터로 복사해둠
            self.original_data = JSON.parse(JSON.stringify(self.selected_data));

            self.toast_text = self.$t("avatar.loaded_existing");
            setTimeout(() => {
              self.show_toast = true;
              setTimeout(() => {
                self.show_toast = false;
              }, 5000);
            }, 1000);
          }
        })
        .catch(function (error) {
          alert(error);
        })
        .finally(function () {
          self.process_queue.pop();
        }); //-- axios
    }, //-- load_avatar_item_map

    // 전체 아바타 데이터 가져와서 랜덤으로 생성
    load_avatar_item_data: function () {
      if (!this.storage.is_logged_in()) return;

      let self = this;
      self.process_queue.push(1);

      this.axios
        .post(
          process.env.VUE_APP_API_URL + "/avatar/list",
          {
            user_id: this.storage.get_user_id(),
            required: "YES",
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then(function (response) {
          let resp_data = response.data;

          // 데이터
          let avatar_data = [];

          // 데이터가 있다면...
          if (typeof resp_data != "undefined" && resp_data != null) {
            for (let i = 0; i < resp_data.length; i++) {
              avatar_data.push(resp_data[i]);
            }
          }

          // 랜덤 선택
          let chosen_data = self.select_random_avatar_item_layers(avatar_data);
          self.selected_data = chosen_data;

          // 오리지널 데이터로 복사해둠
          self.original_data = JSON.parse(JSON.stringify(self.selected_data));

          // 백그라운드에서 저장하기
          self.save_avatar_data_slient();

          self.toast_text = self.$t("avatar.loaded_random");
          setTimeout(() => {
            self.show_toast = true;
            setTimeout(() => {
              self.show_toast = false;
            }, 5000);
          }, 1000);
        })
        .catch(function (error) {
          alert(error);
        })
        .finally(function () {
          self.process_queue.pop();
        }); //-- axios
    }, //-- load_avatar_item_data

    // 랜덤으로 아바타 데이터 생성하기
    select_random_avatar_item_layers: function (data) {
      let ret = []; // {group_id: 0, item_id: 0, data: [],}, ...

      for (let i = 0; i < data.length; i++) {
        let random_index = Math.floor(Math.random() * data[i].item.length);
        let item_data = JSON.parse(
          JSON.stringify(data[i].item[random_index].data)
        );

        let item = {
          group_id: data[i].group_id,
          item_id: data[i].item[random_index].item_id,
          data: item_data,
        };

        ret.push(item);
      }

      return ret;
    }, //-- select_random_avatar_item

    // 아바타 저장하기 이벤트 핸들러
    save_avatar: function () {
      let self = this;

      if (!this.selected_data.length) {
        this.$swal.fire({
          text: this.$t("avatar.error_no_selected"),
          icon: "error",
        });

        return;
      }

      this.$swal
        .fire({
          text: this.$t("common.confirm_save"),
          icon: "question",
          showCancelButton: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            self.save_avatar_data();
          }
        }); //-- swal
    }, //-- save_avatar

    // 아바타 데이터 서버에 저장하기
    save_avatar_data: function () {
      if (!this.storage.is_logged_in()) return;
      if (!this.selected_data.length) return;

      let self = this;
      self.process_queue.push(1);

      this.axios
        .post(
          process.env.VUE_APP_API_URL + "/avatar/save",
          {
            user_id: this.storage.get_user_id(),
            avatar_data: this.selected_data,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then(function (response) {
          if (response.data.result != "OK") {
            self.$swal.fire({
              text: self.$t("common.error_occur"),
              icon: "error",
            });
            return;
          }

          // 스토리지에 아바타 정보 저장
          let user_avatar = response.data.avatar_thumbnail;
          self.storage.set_user_avatar(user_avatar);

          self.$swal
            .fire({
              text: self.$t("common.saved"),
              icon: "success",
            })
            .then(() => {
              self.$router.push("/mypage");
            }); //-- swal
        })
        .catch(function (error) {
          alert(error);
        })
        .finally(function () {
          self.process_queue.pop();
        }); //-- axios
    }, //-- save_avatar_data

    // 백그라운드에서 아바타 저장하기 (랜덤 생성 후 저장)
    save_avatar_data_slient: function () {
      if (!this.storage.is_logged_in()) return;
      if (!this.selected_data.length) return;

      let self = this;

      this.axios
        .post(
          process.env.VUE_APP_API_URL + "/avatar/save",
          {
            user_id: this.storage.get_user_id(),
            avatar_data: this.selected_data,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then(function (response) {
          if (response.data.result != "OK") {
            console.log("An error occurred when sliently saving...");
            return;
          }

          // 스토리지에 아바타 정보 저장
          let user_avatar = response.data.avatar_thumbnail;
          self.storage.set_user_avatar(user_avatar);
        })
        .catch(function (error) {
          alert(error);
        }); //-- axios
    }, //-- save_avatar_data_slient

    // 아바타 원래대로 되돌리기
    click_reset_avatar: function () {
      let self = this;

      this.$swal
        .fire({
          text: this.$t("avatar.confirm_reset"),
          icon: "question",
          showCancelButton: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            if (self.original_data.length) {
              // 오리지널 데이터 복사해서 저장
              self.selected_data = JSON.parse(
                JSON.stringify(self.original_data)
              );
            }
          }
        }); //-- swal
    }, //-- click_reset_avatar
  },
};
</script>

<style scoped>
.customize_wrap {
  padding: 0;
}
.customize_wrap > .title {
  position: relative;
  margin: 0 0 2rem 0;
}
.customize_wrap > .title > .logo {
  display: inline-block;
  vertical-align: middle;

  width: 60px;
  height: 32px;
  background: url("../../assets/img/logo_600.png") no-repeat center center;
  background-size: 100% auto;

  margin: 0;
  padding: 0;

  cursor: pointer;
}
.customize_wrap > .title > h1 {
  display: inline-block;
  vertical-align: middle;
  padding: 0;
  margin: 0 0 0 1rem;

  font-size: 1.8rem;
  font-weight: 700;
}
.customize_wrap > .title > .reset {
  position: absolute;
  right: 0;
  bottom: 50%;
  transform: translate(0, 50%);
  background-color: #fff;
  background-image: url("../../assets/img/icon_return.svg");
  background-repeat: no-repeat;
  background-position: 0.8rem center;
  background-size: auto 60%;
  border: 1px solid #dedede;

  padding: 0.4rem 0.8rem 0.4rem 2.8rem;
  border-radius: 0.4rem;

  color: #333;
  font-size: 1rem;
  font-weight: 700;

  cursor: pointer;
}

.customize_wrap > .group {
  border: 1px solid #dedede;
  border-radius: 0.4rem;
  width: 100%;
}
.customize_wrap > .group > .ps {
  max-width: 100%;
  display: block;
  white-space: nowrap;
}
.customize_wrap > .group > .ps > .container {
  padding: 0.8rem;
}
.customize_wrap > .group > .ps > .container > .box {
  display: inline-block;
  position: relative;
  width: 72px;

  margin: 0 0.8rem 0 0;
  border-radius: 0.4rem;
  overflow: hidden;

  background-color: #efefef;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: auto 60%;

  cursor: pointer;
}
.customize_wrap > .group > .ps > .container > .box::before {
  content: "";
  display: block;
  padding-top: 100%;
}
.customize_wrap > .group > .ps > .container > .box.selected {
  /* border-color: #e74356; */
}
.customize_wrap > .group > .ps > .container > .box.selected::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 2px solid #e74356;
  box-sizing: inherit;
  border-radius: 0.4rem;
  background: transparent;
}
.customize_wrap > .main {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  margin: 1rem 0 0 0;
}
.customize_wrap > .main > .item {
  position: relative;
  width: 120px;
  border: 1px solid #dedede;
  border-radius: 0.4rem;
  margin: 0 1rem 0 0;
}
.customize_wrap > .main > .item > .ps {
  height: 640px;
  max-height: 70vh;
}
.customize_wrap > .main > .item > .ps > .container {
  padding: 0.8rem;
}
.customize_wrap > .main > .item > .ps > .container > .box {
  position: relative;
  width: 100%;

  margin: 0 0 0.8rem 0;
  border-radius: 0.4rem;
  overflow: hidden;

  background-color: #efefef;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;

  cursor: pointer;
}
.customize_wrap > .main > .item > .ps > .container > .box:last-child {
  margin: 0;
}
.customize_wrap > .main > .item > .ps > .container > .box::before {
  content: "";
  display: block;
  padding-top: 100%;
}
.customize_wrap > .main > .item > .ps > .container > .box.selected {
  /* border-color: #e74356;
  border: 2px inset #e74356; */
}
.customize_wrap > .main > .item > .ps > .container > .box.selected::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 2px solid #e74356;
  box-sizing: inherit;
  border-radius: 0.4rem;
  background: transparent;
}
.customize_wrap > .main > .item > .ps > .container > .box > .bought {
  position: absolute;
  left: 0.8rem;
  top: 0.8rem;

  width: 1.6rem;
  height: 1.6rem;

  background-image: url("../../assets/img/icon_hanger.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}

.customize_wrap > .main > .character {
  flex-grow: 1;
  position: relative;
  border: 1px solid #dedede;
  border-radius: 0.4rem;
  overflow: hidden;
}
.customize_wrap > .main > .character::before {
  content: "";
  display: block;
  padding-top: 100%;
}

.customize_wrap > .main > .character > .layer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;

  user-select: none;

  transform: scale(1.1);
  transition: transform 0.4s ease-in-out;
}
.customize_wrap > .main > .character.magnifying > .layer {
  transform: translateY(50%) scale(2.4);
}

.button_wrap {
  margin: 1rem 0 0 0;
  text-align: center;
}
.button_wrap > button {
  margin: 0 0.4rem;
}

.toast {
  position: fixed;
  top: 8rem;
  right: 50%;
  transform: translate(50%, 0);

  min-width: 360px;
  max-width: 90%;

  font-size: 1.2rem;
  font-weight: 700;
  padding: 1rem 2rem;
  border-radius: 4rem;
  background: #fff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);

  text-align: center;

  user-select: none;
}

.toast-enter-active,
.toast-leave-active {
  transition: all 0.3s ease-in-out;
}

.toast-enter-from,
.toast-leave-to {
  opacity: 0;
  margin-top: -3rem;
}

@media (max-width: 600px) {
  .customize_wrap > .title > .logo {
    width: calc(60px * 0.8);
    height: calc(32px * 0.8);
  }
  .customize_wrap > .title > h1 {
    margin: 0 0 0 0.6rem;

    font-size: 1.4rem;
    font-weight: 700;
  }

  .customize_wrap > .title > .reset {
    background-position: center center;
    background-size: auto 60%;

    width: 2rem;
    height: 2rem;
    padding: 0;
    font-size: 0;
  }

  .customize_wrap > .group > .ps > .container {
    padding: 0.4rem;
  }
  .customize_wrap > .group > .ps > .container > .box {
    width: 64px;
    margin: 0 0.4rem 0 0;
  }
  .customize_wrap > .main {
    margin: 0.6rem 0 0 0;
  }
  .customize_wrap > .main > .item {
    width: 86px;
    border: 1px solid #dedede;
    border-radius: 0.4rem;
    margin: 0 0.4rem 0 0;
  }
  .customize_wrap > .main > .item > .ps > .container {
    padding: 0.4rem;
  }
}
</style>
